export const colorPrimary = '#3F5DAF'
export const colorSecondary = '#0CD3AB'

export const colorSuccess = '#0CD3AB'
export const colorSuccessBg = '#B4F1E5'
export const colorSuccessBgDark = '#1F5963'
export const colorAlert = '#F7564D'
export const colorAlertBg = '#FEEEED'
export const colorAlertBgDark = '#633547'
export const colorWarning = '#EBA55D'
export const colorWarningBg = '#FBEDDF'
export const colorWarningBgDark = '#614945'
export const colorInfoText = '#666A85'

export const colorWhite = 'hsla(0, 0%, 100%, 1)'
export const colorGrey = 'hsla(0, 0%, 53%, 1)'
export const colorBlack = 'hsla(0, 0%, 0%, 1)'

export const colorGradientFrom = '#3754AB'
export const colorGradientTo = '#2E3285'

export const colorUIDarkest = '#242746'
export const colorUIDarker = '#2E3253'
export const colorUIDark = '#4B4D69'
export const colorUI = '#80849C'
export const colorUILight = '#949DB0'
export const colorUILighter = '#A8B4D1'
export const colorUILighterPlus = '#CAD4EE'
export const colorUILightest = '#EAEDF8'
export const colorUILightestLighter = '#F1F4FC'
export const colorUILightestPlus = '#F5F7FC'

export const colorTopBar = colorUIDarkest

export const minWidth = 124
export const maxWidth = 116
export const maxWidthBookingPageContent = 97

export const gradient = `linear-gradient(77deg, ${colorGradientFrom} 0%, ${colorGradientTo} 100%)`

export const fontFamilySansSerif = ['Roboto', 'sans-serif']

export const fontSizeSmallest = .8
export const fontSizeSmaller = 1.2
export const fontSizeSmall = 1.4
export const fontSize = 1.6
export const fontSizeLarge = 1.8
export const fontSizeLarger = 2.4
export const fontSizeLargest = 6.4

export const fontWeightRegular = 400
export const fontWeightMedium = 500
export const fontWeightBold = 700

export const spacingLargest = 6.4
export const spacingLarge = 4.8
export const spacing = 3.2
export const spacingSmall =  1.6
export const spacingSmaller = .8
export const spacingSmallest = .4

export const borderRadius = .4
export const borderRadiusLarge = .8

export const transitionSpeedFast = .15
export const transitionSpeed = .3
export const transitionSpeedMs = 300
export const transitionSpeedLonger = .6
export const transitionSpeedLongerMs = 600

export const defaultFontFamily = fontFamilySansSerif
export const defaultFontWeight = fontWeightRegular
export const defaultFontSize = fontSize
export const defaultFontColor = colorGrey
export const defaultLetterSpacing = 0
export const defaultLineHeight = 1.65
export const defaultSpacing = spacing
export const defaultTransitionSpeed = transitionSpeed

export const defaultBorderColor = colorUILightest
export const defaultBackgroundColor = colorUILightestPlus
export const defaultBorderRadius = borderRadius

export const zIndexTooltip = 6
export const zIndexDropdown = 7
export const zIndexDrawerBackground = 8
export const zIndexDrawer = 9
export const zIndexModalBackground = 10
export const zIndexModal = 11

// Refactor
export const paddingModalSmall = 2.4;
export const paddingModal = 3.2;
